import React,{Fragment} from 'react'; 
import './Home.scss'; 
import logo from "../assets/images/ONEWAVE LOGO-05.png"; 
import {Link} from 'react-router-dom'; 

function Home(){
  return(
    <Fragment>
      <div className = "home-wrapper">
          <div className = "home-hero-section">
            
            <div className = "home-text-wrapper">
              <div className = "home-header">
                <img src = {logo} alt = "OneWav Music Group logo"/>
              </div>
              <Link to = "/contact">CONTACT</Link>
            </div>
          </div>
        
      </div>
      
    </Fragment>
  )
};
export default Home; 
