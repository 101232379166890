import './App.scss';
import {BrowserRouter, Routes, Route} from 'react-router-dom'; 

import Home from './pages/Home'; 
import Contact from './pages/Contact'; 
import Footer from './components/Footer'; 
import Navbar from './components/Navbar';



function App() {
  return (
    <BrowserRouter>
    <div className="App">
        <Navbar/>
        <Routes>
          <Route path = "/" element={<Home/>}/>
          <Route path = "contact" element={<Contact/>}/>
        </Routes>
        <Footer/>
    </div>
    </BrowserRouter>
  );
}

export default App;
