import React, {Fragment} from 'react'; 
import PropTypes from 'prop-types'; 
import './styledlink.scss'; 
import link_arrow from '../assets/images/link_arrow.png'
import {Link} from 'react-router-dom';

function StyledLink(props){

  const arrowStyle = {
    height: props.arrowSize ? props.arrowSize : "10px"
  }

  return(
    <Fragment>
      <div className = "styledlink-wrapper">
        {props.external ? <a target = "_blank" rel="noreferrer" href = {props.link}>{props.text}</a>: <Link to = {props.link}>{props.text}</Link>}
             
        <img src = {link_arrow} style = {arrowStyle} alt = "link arrow"></img>
      </div>
      
    </Fragment>

)
}

StyledLink.propTypes = {
  external: PropTypes.bool, 
  text: PropTypes.string, 
  link: PropTypes.string ,
  arrowSize: PropTypes.string, 
}

export default StyledLink;  
