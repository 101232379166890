import React,{Fragment} from 'react'; 
import PropTypes from 'prop-types'; 
import './ContactElement.scss'; 

function ContactElement(props){
    return(
        <Fragment>
            <div className = "ce-wrapper">
                <h3>{props.text}</h3>
                <div className = "ce-input">
                {props.isDropdown ? 
                    <select onChange = {e=> props.setState(e.target.value)}>
                        {props.dropDownOptions.map((option, i) => <option key = {i} value = {option}>{option}</option>)}
                    </select>
                : 
                props.isLarge ? 
                    <textarea 
                    onChange = {e => props.setState(e.target.value)}
                    rows={props.rows} cols = {props.cols}
                    minLength={props.minlength}
                    maxLength={props.maxlength}>
    
                    </textarea> 
                    : 
                    <input type = "text" 
                    onChange = {e => props.setState(e.target.value)} 
                    ></input>
                }
                
                </div>
            </div>
            
        </Fragment>
    )
};


ContactElement.propTypes = {
    text: PropTypes.string, 
    isLarge: PropTypes.bool, 
    isDropdown: PropTypes.bool, 
    dropDownOptions: PropTypes.arrayOf(PropTypes.string), 
    rows: PropTypes.number ,
    cols: PropTypes.number, 
    minlength: PropTypes.number,
    maxlength: PropTypes.number,

  }
  
export default ContactElement; 