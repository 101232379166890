import React, {Fragment, useState, useEffect} from 'react'; 
import './Navbar.scss'; 
import logo from '../assets/images/ONEWAVE_LOGO_SM.png'; 
import StyledLink from './styledlink';
import {Link} from 'react-router-dom'; 

function Navbar(){

    const [hideNavbar, setHideNavbar] = useState(false);
    const [previousYPos, setPreviousYPos] = useState(0); 
    const [underline, setUnderline] = useState(false); 

    //check for user scrolling
    
      useEffect(() => { // on page load 
        window.onscroll = () => { //detects scrolling
          if((window.pageYOffset - previousYPos < 0) || (window.pageYOffset < 50)){ //scrolling up 
            setHideNavbar(false)
          }else{
            setHideNavbar(true)
          }
          if(window.pageYOffset === 0 ){
              setUnderline(false); 
          }else{setUnderline(true)}
          setPreviousYPos(window.pageYOffset); 
        }
        return () => {
          window.unscroll = null;    
        }
      }) 

    const underlinestyle = {
        "borderBottom": "1px solid black"
    }


    return(
        <Fragment>
            <div className='navbar-wrapper' id = {hideNavbar ? "noShowNavbar": "showNavbar"}
            style = {underline ? underlinestyle : null}>
                <div className = "navbar-logo">
                    <Link to = "/">
                        <img style = {{height: "35px"}} src = {logo} alt = "Logo"/>
                    </Link>
                </div>
                <div className = "navbar-contact-btn">
                    <StyledLink link = "/contact" text = "CONTACT"/>
                </div>
            </div>
        </Fragment>
    )
}

export default Navbar; 