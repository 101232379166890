import React, {Fragment} from 'react'; 
import './Footer.scss'; 
import StyledLink from './styledlink'; 
import facebook from "../assets/images/facebook.png"; 
import insta from "../assets/images/instagram.png"; 
import twitter from "../assets/images/twitter.png"; 


function Footer(){
  return(
    <Fragment>
    <div className = "footer-wrapper">
        <div className = "footer-sociallinks">
          <div className = "footer-fb">
            <a href = "https://facebook.com" target = "blank">
              <img alt = "OneWav Music Group Facebook link" src = {facebook}/>
            </a>
          </div>

          <div className = "footer-ig">
            <a href = "https://instagram.com/onewavmg?utm_medium=copy_link" target = "blank">
              <img alt = "OneWav Music Group Instagram link" src = {insta}/>
            </a>
          </div>
          <div className = "footer-tw">
            <a href = "https://twitter.com/onewavmg?s=21" target = "blank">
              <img alt = "OneWav Music Group Twitter link" src = {twitter}/>
            </a>
          </div>
        </div>
        <div className = "footer-em">
            <a href="mailto:info@onewavmusicgroup.com"
                alt = "Contact email address">
              info@onewavmusicgroup.com</a>
          </div>
        <div className = "footer-accessibilitylinks">
            <StyledLink link = "/" text = "Home"/>
            <StyledLink link = "/contact" text = "Contact"/>
        </div>

    </div> 
    </Fragment>
    )
}
export default Footer;
