import React,{Fragment, useState} from 'react'; 
import emailjs from 'emailjs-com';
import ContactElement from '../components/ContactElement';
import './Contact.scss'; 

function Contact(){

    const [firstname, setFirstName] = useState(""); 
    const [lastname, setLastName] = useState(""); 
    const [purpose, setPurpose] = useState("BOOKING"); 
    const [email, setEmail] = useState(""); 
    const [message, setMessage] = useState(""); 
    const [alert, setAlert] = useState(""); 

    //handles submission of form
    const handleSubmit = () => {
        if(firstname && lastname && purpose && email && message){
            setAlert(""); 
            var payload = {
                firstname: firstname,
                lastname: lastname, 
                purpose: purpose,
                email: email, 
                message: message
            }
            emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, payload, process.env.REACT_APP_EMAIL_USER_ID)
            .then(result => {
            setAlert("Message sent successfuly."); 
            }).catch(result => {
            setAlert("Error sending message."); 
            })

        }else{
            setAlert("Please fill out all fields."); 
        }

    }

    const alert_active = {
        transition: "0.25s cubic-bezier(.17,.67,.83,.67)", 
        position: "fixed",
        left: "50%", 
        transform: "translateX(-50%)", 
        bottom: "20px", 
        backgroundColor: "white",
        borderRadius: "5px",   
        boxShadow: "0.3em 0.3em 1em rgba(0,0,0,0.3)", 
        padding: "7px"
    }
    const alert_inactive = {
        transition: "0.25s cubic-bezier(.17,.67,.83,.67)", 
        position: "fixed",
        left: "50%", 
        transform: "translateX(-50%)", 
        bottom: "-30px", 
        backgroundColor: "white",
        borderRadius: "5px",   
        boxShadow: "0.3em 0.3em 1em rgba(0,0,0,0.3)", 
        padding: "7px"
    }

    return(
        <Fragment>
            <div className = "contact-wrapper">
                <div className = "contact-header">
                    <h1>CONTACT US</h1>
                </div>
                <div className = "contact-info-wrapper">
                    <div className = "contact-firstname">
                        <ContactElement  
                        text = "First Name" 
                        setState = {setFirstName}/>
                    </div>
                    <div className = "contact-lastname">
                        <ContactElement
                        text = "Last Name"
                        setState = {setLastName}/>
                    </div>
                    <div className = "contact-purpose">
                        <ContactElement 
                        text = "Purpose" isDropdown = {true}
                        dropDownOptions={["BUSINESS INQUIRY","BOOKING", "MIX AND MASTER",  "OTHER"]}
                        setState = {setPurpose}/>
                    </div>
                    <div className = "contact-email">
                        <ContactElement
                        text = "Email"
                        setState = {setEmail}/>
                    </div>
                    <div className = "contact-message">
                        <ContactElement className = "contact-message" 
                        text = "Message" isLarge = {true} 
                        rows={5}
                        setState = {setMessage}/>
                    </div>
                    <div className = "contact-submit">
                        <p onClick = {handleSubmit}>SUBMIT</p>
                    </div>
                </div>
            </div>
            <div style = {alert !== ""? alert_active : alert_inactive}>
                {alert}
            </div>
        </Fragment>
    )
}; 

export default Contact; 